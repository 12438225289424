<template>
  <div>
    <v-card
      :loading="carregandoBuscar"
      elevation="1"
    >
      <v-toolbar
        color="grey lighten-3 display-1"
        flat
        dense
      >
        <v-toolbar-title>
          #{{ alertaPergunta.id || '--' }}
        </v-toolbar-title>
      </v-toolbar>
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="texto_pergunta"
                  name="Descrição"
                >
                  <v-text-field
                    v-model="perguntaNova.texto_pergunta"
                    outlined
                    dense
                    hide-details="auto"
                    label="Descrição"
                    :disabled="carregandoBuscar"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col>
                <v-switch
                  v-model="perguntaNova.obrigatoriedade_pergunta"
                  :disabled="carregandoBuscar"
                  :true-value="1"
                  :false-value="0"
                  class="mt-0"
                  hide-details="auto"
                  label="Pergunta Obrigatória"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-switch
                  v-model="perguntaNova.status"
                  :disabled="carregandoBuscar"
                  :true-value="1"
                  :false-value="0"
                  hide-details="auto"
                  class="mt-0"
                  label="Ativo"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>
      <v-card-actions>
        <v-row>
          <v-col
            cols="12"
            class="text-left"
          >
            <btn-salvar
              :carregando="carregandoSalvar"
              @click="salvar"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import _ from 'lodash'

  export default {
    props: {
      validacaoFormulario: {
        type: Object,
        default: () => { return {} },
      },
      carregandoSalvar: {
        type: Boolean,
        default: false,
      },
      carregandoBuscar: {
        type: Boolean,
        default: false,
      },
      alertaPergunta: {
        type: Object,
        default: () => { return {} },
      },
    },

    data () {
      return {
        perguntaNova: {
          texto_pergunta: null,
          obrigatoriedade_pergunta: 1,
          status: 1,
        },
      }
    },

    watch: {
      validacaoFormulario (val) {
        if (!val) return

        return this.$refs.observer.setErrors(val)
      },

      alertaPergunta: {
        immediate: true,
        handler (val) {
          if (_.isEmpty(val)) return false

          this.perguntaNova = val
        },
      },
    },

    methods: {
      salvar () {
        this.$emit('salvar', this.perguntaNova)
      },
    },

  }
</script>
